.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    color: #73879c;
  }

  /* Navbar */
  .navbar {
    background: #ededed;
    border-bottom: 1px solid #d9dee4;
    margin-bottom: 10px;
  }
  
  .navbar a {
      cursor: pointer;
  }
  /* End Navbar */

  /* Sidebar */
  #sidebar.active {
    margin-left: -230px;
  }
  
  #sidebar {
    min-width: 230px;
    max-width: 230px;
    min-height: 100vh;
    background: #2a3f54;
    color: #fff;
    transition: all 0.3s;
  }
  
  #sidebar .sidebar-header {
    padding: 20px;
    background: #2a3f54;
  }
  
  #sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #2a3f54;
  }
  
  #sidebar ul p {
    color: #fff;
    padding: 10px;
  }
  
  #sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: #fff;
  }
  #sidebar ul li a:hover {
    background: rgba(255,255,255,.06);
    text-decoration: none;
  }
  
  #sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #2a3f54;
  }

  #sidebar .menu-section {
    padding-left: 15px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 0;
    text-shadow: 1px 1px #000;
}
  /* End Sidebar */

  ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #2a3f54;
    color: #fff;
  }
  
  a[data-toggle="collapse"] {
    position: relative;
  }
  
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  
  #content {
    width: 100%;
  }
  
  @media (max-width: 768px) {
    #sidebar {
        margin-left: -230px;
    }
    #sidebar.active {
        margin-left: 0;
    }
  }
  
  /**
  */
  .mapouter{position:relative;text-align:right;height:auto;width:100%;}
  .gmap_canvas {overflow:hidden;background:none!important;height:auto;width:100%;}

  .sidebar-footer {
    bottom: 0px;
    clear: both;
    display: block;
    padding: 2px;
    position: fixed;
    width: 230px;
    z-index: 1001;
	background-color: #122436;
    min-width: 230px;
    max-width: 230px;
	font-size: 12px;
	font-style: italic;
  }