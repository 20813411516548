div.imageInputDesc {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}
/* ONLY BUTTON */
/* .inputfile i don't use d-none because otherwise i can't use <tab> keyboard feature */
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.inputfile + label {
    max-width: 80%;
    font-size: 1.25rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
}
.inputfile + label {
    color: rgb(227, 204, 206);
    background-color: rgb(161, 36, 51);
}
/* FULL */
.inputfile.full + label {
	border-color: rgb(143, 32, 45);
    background-color: rgb(49, 26, 28);
}
.inputfile.full + label {
    color: rgb(215, 75, 92);
}
.inputfile.full + label {
    border: 1px solid #d3394c;
    background-color: #f1e5e6;
    padding: 0;
}

.inputfile.full + label span {
    width: 200px;
    min-height: 2em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
}

.inputfile.full + label span, .inputfile.full + label strong {
    padding: 0.625rem 1.25rem;
}

.inputfile.full + label strong {
    color: rgb(227, 204, 206);
    background-color: rgb(161, 36, 51);
}

.inputfile.full + label strong {
    height: 100%;
    color: #f1e5e6;
    background-color: #d3394c;
    display: inline-block;
}

.inputfile.full + label span, .inputfile.full + label strong {
    padding: 0.625rem 1.25rem;
}

.preloader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
}