.divider .container {
	display: flex !important;
	margin: 20px 0 20px 0;
	align-items: center;
}

.divider .border {
	border-bottom: 1px solid black;
	width: 100%;
}

.divider .content {
	width: 50%;
	text-align: center;
	padding: 0 10px 0 10px;
	font-weight: 800;
}