@media screen and (max-width: 525px) {

	#createBody {
		padding: 0;
	}
	
	#createBody .card-body {
		padding: .5rem;
	}

  #createBody .card-body .row {
		margin: 0;
	}
	
	#createBody .card {
		border: 0;
	}

}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
    font-family: "Helvetica Neue",Roboto,Arial,"Droid Sans",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-size: 14px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'FontLabware';
  src: url('fonts/FontLabware.eot');
  src: url('fonts/FontLabware.eot?#iefix') format('embedded-opentype'),
      url('fonts/FontLabware.woff2') format('woff2'),
      url('fonts/FontLabware.woff') format('woff'),
      url('fonts/FontLabware.ttf') format('truetype'),
      url('fonts/FontLabware.svg#FontLabware') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fl {
  display: inline-block;
  font: normal normal normal 14px/1 FontLabware;
  font-size: 18px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
.fl-lg {
  font-size: 1.33333333em;
  line-height: .75em;
  vertical-align: -15%
}

.fl-2x {
  font-size: 2em
}

.fl-3x {
  font-size: 3em
}

.fl-4x {
  font-size: 4em
}

.fl-5x {
  font-size: 5em
}

.fl-10x {
  font-size: 10em
}

i.fl {
 vertical-align: sub; 
}


.fl-fw {
  width: 1.28571429em;
  text-align: center
}

.fl-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none
}

.fl-ul>li {
  position: relative
}

.fl-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: .14285714em;
  text-align: center
}

.fl-li.fl-lg {
  left: -1.85714286em
}

.fl-border {
  padding: .2em .25em .15em;
  border: solid .08em #eee;
  border-radius: .1em
}

.fl-pull-left {
  float: left
}

.fl-pull-right {
  float: right
}

.fl.fl-pull-left {
  margin-right: .3em
}

.fl.fl-pull-right {
  margin-left: .3em
}

.pull-right {
  float: right
}

.pull-left {
  float: left
}

.fl.pull-left {
  margin-right: .3em
}

.fl.pull-right {
  margin-left: .3em
}

.fl-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear
}

.fl-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8)
}

@-webkit-keyframes fa-spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

@keyframes fa-spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

.fl-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg)
}

.fl-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg)
}

.fl-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg)
}

.fl-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1)
}

.fl-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1)
}

:root .fl-rotate-90,
:root .fl-rotate-180,
:root .fl-rotate-270,
:root .fl-flip-horizontal,
:root .fl-flip-vertical {
  filter: none
}

.fl-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle
}

.fl-stack-1x,
.fl-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center
}

.fl-stack-1x {
  line-height: inherit
}

.fl-stack-2x {
  font-size: 2em
}

.fl-inverse {
  color: #fff
}

.fl-doc-search:before {
  content: "\f091"
}

.fl-zreport:before {
  content: "\f09d"
}

.fl-gauge:before {
  content: "\f0c6"
}

.fl-doc-euro:before {
  content: "\f0c7"
}

.fl-smartview:before {
  content: "\f0d2"
}

.fl-warehouse:before {
  content: "\f0c4"
}

.fl-customer:before {
  content: "\f0a8"
}

.fl-home:before {
  content: "\f0c8"
}

.fl-4co-logo:before {
  content: "\f0c5"
}

.fl-fork:before {
  content: "\f0a6"
}

.fl-avg-cover:before {
  content: "\f0ca"
}

.fl-avg-table:before {
  content: "\f0cb"
}

.fl-avg-bar:before {
  content: "\f0c9"
}

.fl-waiter:before {
  content: "\f0f0"
}

/** monospace font for receipts **/
.monospace-font {
  font-family: monospace, monospace;
}

.modal-header > button.close > span {
	font-size: inherit;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 5px;
}

.flex-row > label {
  display: flex;
  flex-grow: 1;
  max-width: 50%;
  margin: 0;
}

.flex-row > div {
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.flex-row > Input {
  display: flex;
  flex-grow: 1;
  max-width: 50%;
}

.flex-row > select {
  display: flex;
  flex-grow: 1;
  max-width: 50%;
}

.flex-row > FormGroup {
  display: flex;
  flex-grow: 1;
  max-width: 50%;
  margin: 0;
}

.flex-row > Switch {
  display: flex;
  flex-grow: 1;
  max-width: 50%;
  margin: 0;
}

.flex-align-center {
  display: flex;
  align-items: center;
}