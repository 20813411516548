@media screen and (max-width: 425px) {
	#receiptCard .card-body, #receiptCard .card-body {
		padding: 5px;
		overflow: hidden;
	}

	#receiptCard .card, #receiptCard .card {
		border: 0;
	}

	#receiptCard .card-body > .row > .col-xl-6 {
		padding-left: 2px;
		padding-right: 2px;
	}
}