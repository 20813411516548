@media screen and (max-width: 525px) {
	#edit-devices-modal .modal-body {
		padding: 0.50rem;
	}
	#rtModalBody {
		padding: 0;
	}
	
	#rtModalBody .card-body {
		padding: 0.50rem;
	}
	
	#rtModalBody .card {
		border: 0;
	}

}